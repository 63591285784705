import { gsap } from "gsap";
import LocomotiveScroll from "locomotive-scroll";

const scroller = new LocomotiveScroll({
  el: document.querySelector("[data-scroll-container]"),
  smooth: !0,
  getDirection: !0,
  getSpeed: !0,
  reloadOnContextChange: !0,
  multiplier: 0.65,
  lerp: 0.1,
  tablet: { breakpoint: window.tabletBreakpoint, smooth: !1, getDirection: !0, getSpeed: !0 },
  mobile: { smooth: !1, getDirection: !0, getSpeed: !1 }
});

  /* mobile menu */
  document.querySelector(".mob-navigation-toggle").addEventListener("click", (event) => {
    if ((" " + document.querySelector("body").className + " ").indexOf(" mobile-menu-open ") > -1) {
      document.querySelector("body").classList.remove("mobile-menu-open");
      document.querySelector(".vh-header").classList.remove("mobile-menu-open");
    } else {
      document.querySelector("body").classList.add("mobile-menu-open");
      document.querySelector(".vh-header").classList.add("mobile-menu-open");
    }
    event.preventDefault();
  });
